import React from "react"
import AGBPatientInnenContent from "../components/AGBPatientInnenContent"
import Layout from "../components/Layout"
import Section from "../components/Section"

export interface AGBPatientInnenProps {}

const AGBPatientInnen: React.SFC<AGBPatientInnenProps> = () => {
  return (
    <Layout>
      <Section>
        <AGBPatientInnenContent />
      </Section>
    </Layout>
  )
}

export default AGBPatientInnen
